div.toolbar button.active {
  color: #5890ff;
  border-radius: 3px;
}

.output-header {
  height: 31px;
  width: 517px;
  color: #5C5C5C;
  font-size: 26px;
  font-weight: bold;
  line-height: 31px;
}

.output-paragraph {
  color: #5C5C5C;
  font-size: 16px;
  line-height: 23px;
}

.output-label {
  color: #212529;
  font-size: 12px;
  letter-spacing: 0.7px;
  line-height: 15px;
}

.output-section-header {
  color: #5C5C5C;
  font-size: 26px;
  font-weight: bold;
  line-height: 31px;
  cursor: pointer;
}

.chevron {
  margin-right: 30px;
  position: relative;
  display: block;
  height: 14px; /*height should be double border*/
}

.chevron::before,
.chevron::after {
  position: absolute;
  display: block;
  content: "";
  border: 14px solid transparent; /*adjust size*/
}

/*Change the four instances of 'top' below to rotate (top/right/bottom/left)*/
.chevron::before {
  top: 0;
  border-top-color: #007BFF; /*Chevron Color*/
}
.chevron::after {
  top: -3px; /*adjust thickness*/
  border-top-color: #fff; /*Match background colour*/
}

// Stop the scroll from appearing when the text doesn't overflow
div.DraftEditor-editorContainer {
  height: 200px;
}