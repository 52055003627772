.map-select {
  width: 25vw;
}

.listed-job-codes{
  max-height: 3rem;
}

.listed-job-codes {
    &.form-control:disabled{
      background-color: #ffffff;
    }
}