.manage-countries-container {
  flex-direction: column;
}

.manage-countries-table {
  display: table;
}

.manage-countries-table th, .manage-countries-table td {
  padding: 0.5rem 4rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.remove-region-button {
  margin-top: 80%;
}

.collapse-toggle {
  cursor: pointer;
}