.navbar {
  height: 20vh;
  
  .gw__navbar {
    width: 100%;
  }
}

.navbar-selected-country {
  text-align: center;
}

.country-home-card{
  height: 100%;
  flex-direction: column;
  text-align: center;
}