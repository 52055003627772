.gw__job-level-mappings{
  max-width: 50%;
  margin-right: 0;
  margin-left: auto;

  &.form-control:disabled{
    background-color: #ffffff;
  }
}

.gw__job-level-mapping-select-container {
  min-width: 45%;
}