div.DraftEditor-root {
  background-color: #FBFBFB;
  height: 250px;
  border: 1px solid #DDDDDD;
  border-radius: 3px;
  overflow-y: scroll;
  padding:20px;
  font-size: 18px;
  font-family: 'calibri', sans-serif;
}

div.toolbar {
	width: 322px;
  background-color: #FFFFFF;
}

div.toolbar button {
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 3px;
}
